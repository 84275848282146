import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutusPage from "../components/aboutus/index"
const aboutus = () => {
    return (
        <Layout>
            <SEO
                title="Innoway Systems"
                desc="Innoway Systems is an engineering services outsourcing company in India serving to domestic and international customers."
                pathname="/aboutus/"
                keywords="3D CAD solid modeling,2D drafting"
            />
            <AboutusPage />
        </Layout>
    )
}

export default aboutus
