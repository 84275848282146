import React from "react"

const Management = () => {
        return (
                <section className="world">
                        <div className="container pt-4">
                                <h3 className="font-weight-bold text-left">Management Team</h3><br />
                                <div className="row">
                                        <div className="col-12 col-lg-3 text-center">
                                                <img src="/img/anand-336x420-1-150x150.jpg" alt=""></img>
                                        </div>

                                        <div className="col-12 col-lg-9 text-left">
                                                <p>

                                                        Management Team
Anand – Chief Operating Officer Graduated professional holding Bachelor’s degree in Electronics and Communication from Government College of Technology, Coimbatore. Having associated with leading automotive ancillary, he has over 33 years experience in automotive industry and has many accomplishments in his career. He has witnessed the technological changes of the Indian auto industry, and has the knowledge of modern systems and legacy products; the talent is unique in industry. He holds three patents in his name and presented papers in SAE international journal. He has great passion in design with experience in manufacturing; he could certainly add the best value to the products. Highly committed towards fulfilling customer’s requirement and brings valuable experience to Innoway Systems.</p>
                                                <p>Ramesh D – Chief Technology Officer PCB Design & Embedded Design – Obtained his MS degree in Electronics. Ramesh had joined as design engineer and moved up to team leader at Enixs Technology India (P) Ltd. Later started his own company named as Chip Crafts and is focused in the fields of PCB design and embedded systems, serving as Chief Technology Officer and has established the business well. Ramesh adds to Innoway Systems and its customers great value and service.</p>

                                        </div>
                                </div>
                        </div>
                </section>
        )
}

export default Management