import React from "react"

import Slider1 from "./slider1"
import Content from "./content"
import Products from "./products"
import Management from "./Management"
import Infrastructure from "./Infrastructure"

const AboutusPage = () => {
    return (
        <>
            <Slider1 />
            <Content />
            <Products />
            <Infrastructure />
            <Management />
        </>
    )
}

export default AboutusPage
