import React from "react"

const content = () => {
	return (
		<section>
			<div className="container pt-4">
				<div className="row">
					<div className="text">
						<h4 className="innoway">Innoway Systems</h4>
						<p>is an engineering services outsourcing company in India serving to domestic and international customers.</p>
						<p>Our CAD service include 2D drafting, 3D modeling, CAD conversion, 3D rendering and Architectural rendering. The engineering service include Reverse engineering, Proto-typing, Mold making and Sourcing.</p>
						<p>Our service offers include product design, analysis and engineering process support using cutting-edge CAD / CAM / CAE technology to various industries like manufacturing, automotive, machine tool, automation, process industry, fabrication, construction, health care, etc.  Helping customers realize their product from concept to manufacturing.</p>
						<p>We are serving the industry from the year 2010. We have made remarkable performance and has earned good customer base in a short span. We have acquired experience close to 92,000 man-hours in design, drafting, 3D modeling, CAD conversion and architectural drafting on various projects. Also, we have clocked about 19,000 man-hours on mold making for production tooling and soft-tooling.</p>
						<p>Our headquarters is located at&nbsp;<a href="http://en.wikipedia.org/wiki/Coimbatore">Coimbatore</a>, a tier 2 city preferred IT destination, which is contributing USD $2bn to the Indian economy, the next big city to&nbsp;<a href="http://en.wikipedia.org/wiki/Chennai">Chennai</a>  [the Detroit of India].  The CAD service demands high-skilled resource, and the city is the prime source of talented pool of engineering professionals with around 50 engineering institutions situated in close proximity.  The city is well connected by road, rail and air. Our CAD services business model has in-built gate check points to ensure the quality output.  Focus is given to develop an attitude of making first time right.  The resources are engaged in continuous learning cycles in a periodic manner. Also, training is imparted at regular intervals. Attention is paid on retention of key resources to ensure quality output.</p>
						<p>In general, our customers are benefited by 40% to 60 % on their annual budget by outsourcing the CAD services to us. This is achieved by means of reduction in capital investment, employee cost, floor space, etc.  Every project is processed with a schedule agreed by the customer and delivered on time. The project schedules are monitored and reported to the customer. The data confidentiality is ensured with NDA signed off before project kickoff.</p>
						<p>The management is involved in day-to-day operations and ensures the timely deliverable to its customers. We are having a over 25 years of experience in the automotive field. The team possesses very good experience by working with overseas client projects. We can be reached at any point of time. We will respond to your queries in less than four hours. Due to advantage of time zone difference, we are able to serve our US and Europe customers with quick turnaround time. Also, we shall be able to respond within 12 hours for certain inputs, which will be overnight delivery for customers.</p>
						<p>We are capable of designing parts right from plastic part, sheet-metal to big valves. We have showcased our projects in the product gallery. The tangible benefit to customer is “bottom-line growth.” The intangible is that the resources can be redeployed to new avenues for development, work on high value products, create innovative new products and enhance its value for top line growth.</p>
					</div>
				</div>
			</div>
		</section>

	)
}

export default content