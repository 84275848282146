import React from "react"

const products = () => {
	return (
		<>
			<section className="world">
				<div className="container pt-4">
					<div className="row">
						<div className="col-12 col-lg-4">
							<h5>Some Cool Facts</h5>
							<h2>Numbers Speak For Themselves</h2>
						</div>
						<div className="col-12 col-lg-2">

							<h1 className="font-weight-bold">20</h1>
							<h4>Products</h4>
						</div>
						<div className="col-12 col-lg-3">
							<h1 className="font-weight-bold">10</h1>
							<h4>Years of Experience</h4>
						</div>
						<div className="col-12 col-lg-3">
							<h1 className="font-weight-bold">450</h1>
							<h4>Completed Projects</h4>
						</div>

					</div>
				</div>
				<section className="Facts">
					<div className="container pt-5">
						<h4>Profile</h4>
						<div className="row">
							<div className="col-12 col-lg-5">
								<p><strong>Factors</strong><br />Size of the company: Single owned proprietorship firm<br />Investment &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: Capital investment – Rs 1,000, 000 (USD 15,000)<br />Location &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : Coimbatore, India,&nbsp;<a href="http://tools.wmflabs.org/geohack/geohack.php?pagename=Coimbatore&amp;params=11_1_6_N_76_58_21_E_type:city(1050721)_region:IN-TN"><em>Coordinates:&nbsp;11°1′6″N&nbsp;76°58′21″E</em></a><br />Infrastructure &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: 800 Sq Ft&nbsp; – 8 Desktop systems | 24 hour Power backup<br />Culture &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: Customer centric, practice PDCA, follows business ethics,<br />Flexible contract &nbsp; &nbsp; &nbsp; &nbsp;: Contracts are always negotiable<br />Pricing &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : Ballpark price USD $12 per hour<br />Security &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: The IP rights and data&nbsp;security&nbsp;ensured.</p>
								<p><p><span>Employee information</span><br /><span>Employed professionals with minimum graduation in engineering discipline</span><br /><span>Employee strength 9 Full time Designers 2 Part time Designers-Status as on April 1, 2016</span><br /><span>Work experience of employees-92,000 man-hours combined experience in CAD design from more than 125 projects</span></p></p>
							</div>
							<div className="col-12 col-lg-4">

								<p><span>Expertise</span><br />Mechanical design and drafting<br />3D CAD solid modeling<br />2D drafting<br />Detailing &amp; drafting services<br />Surface design<br />Assembly design<br />Architectural Drafting</p>
								<p><span>CAD conversion</span><br />2D to 3D conversion<br />Paper-to-CAD conversion<br />PDF to AutoCAD<br />TIFF to AutoCAD<br />JPEG to AutoCAD</p>
								<p><span>Rendering &amp; Animation</span><br />Mechanical 3D rendering<br />3D animation<br />Walk-through<br />Architectural 3D rendering<br /><span>Reverse engineering</span></p>
							</div>
							<div className="col-12 col-lg-3">
								<p><strong>Business hours</strong><br /><span>Open: Monday – Saturday</span></p>
								<p><span>9.00 AM to 5.00 PM</span><span>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
								<p><span>Weekly off: Sunday</span><br /><br /></p>
								<p><span><strong>Responsible contacts</strong></span><br /><strong>Anand &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong><br /><strong>Chief Operating Officer &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong><br /><strong>anand@innoway.in;</strong><br />(+91)890 304 0044<br />Skype:&nbsp;anand.cbe3</p>

							</div>


						</div>
					</div>
				</section>
			</section>

		</>
	)
}

export default products