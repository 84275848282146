import React from "react"

const slider1 = () => {
	return (
		<section>
			<div className="parallax">
				<img src="/img/bg-02-free-img.jpg" alt="" />
				<div className="overlay"></div>
				<div className="headercontent">
					<h1>ABOUT US</h1>
				</div>
			</div>
		</section>
	)
}

export default slider1
