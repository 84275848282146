import React from "react"

const Infrastructure = () => {
        return (
                <section className="products-card">
                        <div className="container">
                                <h4>Infrastructure</h4>
                                <div className="row">
                                        <div className="col-12 col-lg-6 text-center">
                                                <img src="/img/inno1.jpg" alt=""></img>
                                        </div>

                                        <div className="col-12 col-lg-6 text-left">

                                                <img src="/img/inno2.jpg" alt=""></img>
                                        </div>
                                </div>
                        </div>
                </section>
        )
}

export default Infrastructure